import {calltouchModId, calltouchSiteId} from '@config/telemetry';
import apiClient from '@api/entities/apiClient';
import {CalltouchValues} from './types/Telemetry';

export const sendToCalltouch = async (values: CalltouchValues) => {
  const {data} = await apiClient.get(
    `https://api.calltouch.ru/calls-service/RestAPI/requests/${calltouchSiteId}/register/`,
    {
      params: {
        ...values,
        requestUrl: location.href,
        sessionId: (window as any).ct('calltracking_params', calltouchModId)?.sessionId,
      },
    },
  );
  return data;
};

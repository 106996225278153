import {SetStateAction} from 'react';
import {ApplicationFormInitialValues} from '@api/types/CallBackFormSchemeType';
import {BackendError} from '@api/types/MainPage';
import {Dispatch} from '@reduxjs/toolkit';
import {changeInitialValue} from '@store/slices/roboDealWizard';
import {FormikErrors, FormikValues} from 'formik';
import {clone, isNull} from 'lodash';

export const displayBackendErrors = (
  errorsList: BackendError[],
  setErrors?: (errors: FormikErrors<ApplicationFormInitialValues>) => void,
) => {
  const newErrors: any = {};
  errorsList.forEach(item => {
    newErrors[item.prop] = item.error;
  });
  if (setErrors) {
    setErrors(newErrors);
  }
};

export const filterValuesFieldsByGroupNum = (
  formValues: FormikValues,
  groupNum: number,
) => {
  const filteredFields: Record<string, any> = {};
  let hasTrueValue = false;
  let inputFields: string[] = [];
  let trueValueField: string | null = null;

  for (const key in formValues) {
    if (key.startsWith(`field_${groupNum}_`) || key.startsWith(`group_${groupNum}_`)) {
      filteredFields[key] = formValues[key];
    }
    if (key.startsWith(`group_${groupNum}_`)) {
      if (formValues[key] === true || formValues[key] === '1') {
        hasTrueValue = true;
      }
      trueValueField = key;
      const groupKey = key;
      const fieldKeys = Object.keys(formValues).filter(k =>
        k.startsWith(`field_${groupNum}_`),
      );
      inputFields = fieldKeys.filter(field => {
        const num = field.split('_').slice(2)[0];
        return groupKey.split('_').slice(2).includes(num);
      });
    }
  }

  return {filteredFields, hasTrueValue, inputFields, trueValueField};
};

export const checkAvailabilityFields = (
  groupNum: number,
  itemField: string,
  itemType: string,
  values: FormikValues,
  setCurrentGroupFields: any,
  setShouldClearFields: any,
  shouldClearFields: boolean,
) => {
  const {filteredFields, hasTrueValue, inputFields, trueValueField} =
    filterValuesFieldsByGroupNum(values, groupNum);

  switch (itemType) {
    case 'checkbox':
      if (itemField === 'iseqconfirmed') {
        return false;
      }
      if (hasTrueValue && trueValueField === itemField) {
        return false;
      }
      if (hasTrueValue && !isNull(trueValueField) && trueValueField !== itemField) {
        return true;
      }
      if (!hasTrueValue && isNull(trueValueField)) {
        // const fields = clone(filteredFields);
        // Object.keys(filteredFields).forEach(key => {
        //   if (key.startsWith(`field`)) {
        //     fields[key] = '';
        //   }
        // });
        // console.log('fields', fields);
        // dispatch(changeInitialValue({step: '3', values: {...values, ...fields}}));
        setCurrentGroupFields(() => {
          setShouldClearFields(true);
          return filteredFields;
        });
        return false;
      }
    case 'text':
      if (hasTrueValue && inputFields.includes(itemField)) {
        return false;
      }
      if (hasTrueValue && !inputFields.includes(itemField)) {
        return true;
      }
    default:
      return true;
  }
};
